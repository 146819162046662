import React, {Component} from 'react';
import {Layout, Menu, Icon} from 'antd';
import {NavLink, withRouter} from 'react-router-dom';
import useGlobalState from '../../GlobalState';
import styles from './NavMenu.module.css';
import history from '../../router/history';

const {Sider} = Layout;

const createMenuItems = (menuDefinition) => {
    const subMenuMap = {};
    const render = menuDefinition.map(menu => {
        if (menu.subMenu) {
            return (
                <Menu.SubMenu key={menu.path} title={<span><Icon type={menu.icon}/><span>{menu.label}</span></span>}>
                    {menu.subMenu.map(subMenu => {
                        const path = menu.path + subMenu.path;
                        subMenuMap[path] = menu.path;
                        return (
                            <Menu.Item key={path}>
                                <NavLink to={path}>
                                    {subMenu.icon && <Icon type={subMenu.icon}/>}
                                    <span>{subMenu.label}</span>
                                </NavLink>
                            </Menu.Item>
                        );
                    })}
                </Menu.SubMenu>
            )
        }
        else {
            return (
                <Menu.Item key={menu.path}>
                    <NavLink to={menu.path}>
                        {menu.icon && <Icon type={menu.icon}/>}
                        <span>{menu.label}</span>
                    </NavLink>
                </Menu.Item>
            );
        }
    });
    return {subMenuMap, render};
}

const renderMenu = (menuDefinition, currentPath) => {
    const {subMenuMap, render} = createMenuItems(menuDefinition);

    const subMenuKey = subMenuMap[currentPath];
    const openKeys = subMenuKey ? [subMenuKey] : []

    return (
        <Menu theme="dark" mode="inline" defaultOpenKeys={openKeys} selectedKeys={[currentPath]} inlineCollapsed={true}>
            {render}
        </Menu>
    )
}

const NavMenu = function ({location, collapsed, siteMeta}) {
    let [user] = useGlobalState('user');
    user = user || {}

    return (
        <Sider theme={siteMeta.basic.theme} trigger={null} collapsible collapsed={collapsed}>
            <div className={`${styles.logo} pointer`} onClick={() => history.push("/index")}>
                <img alt="logo" src="https://gw.alipayobjects.com/os/s/prod/antv/assets/image/icon/g6-b4554.svg"/>
                <h1>{user.instanceName}</h1>
            </div>
            {renderMenu(siteMeta.menu, location.pathname)}
        </Sider>
    )

}

export default withRouter(NavMenu);