import { Layout } from "antd";
import React, { useMemo } from 'react';
import FooterComponent from "../component/pure/Footer";

const {Content, Footer } = Layout;

const BottomFooterLayout = ({children, backgroundImage, backgroundColor, ...rest}) => {
  const layoutStyle = useMemo(() => {
    if (backgroundImage) {
      return {minHeight:'100vh', background: 'url('+backgroundImage+')', backgroundSize: 'cover'};
    }
    return {minHeight:'100vh'};
  }, [backgroundImage])

  const contentStyle = useMemo(() => {
    if (backgroundColor) {
      return { padding: '0 50px', backgroundColor: backgroundColor};
    }
    return { padding: '0 50px'}
  }, [backgroundColor])

  const footerStyle = useMemo(() => {
    if (backgroundColor) {
      return {backgroundColor: backgroundColor };
    }
    return null
  }, [backgroundColor])
  return (
    <Layout style={layoutStyle}>
      <Content style={contentStyle}>{children}</Content>
      <Footer style={footerStyle}>
        <FooterComponent />
      </Footer>
    </Layout>
  );
}


export default BottomFooterLayout;