import React, {Suspense} from 'react';
import { Router, Route,Switch, Redirect } from "react-router-dom";
import router from './router/router';
import { Skeleton, ConfigProvider } from 'antd';
import { GlobalStateProvider } from './GlobalState';
import RouterListenner from './router/RouterListenner';
import history from './router/history';
import zhCN from 'antd/es/locale/zh_CN';
import MenuLayout from './layout/MenuLayout';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn')

const renderPage = (item) => {
  //const Layout = item.layout();
  if (item.component) {
    const Child = typeof(item.component) == 'string' ? React.lazy(() => import(`${item.component}`)) : item.component;
    const Page =  props => <Child {...props} />
    return <Route path={item.path} key={item.path} component={Page}/>
  } else if (item.to) {
    return <Redirect from={item.path} to={item.to}/>
  }
  return null;
}



function App() {
  return (
    <ConfigProvider locale={zhCN}>
    <GlobalStateProvider>
      
        <Router history={history}>
          <RouterListenner>
            <Suspense fallback={<MenuLayout><Skeleton active /></MenuLayout>}>
              <Switch>{router.map(renderPage)}</Switch>
            </Suspense> 
          </RouterListenner>
        </Router>
        </GlobalStateProvider>
      </ConfigProvider>
    
  );
}

export default App;
