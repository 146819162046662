import React, {useLayoutEffect, useCallback} from 'react';
import {withRouter} from "react-router-dom"
import useGlobalState from '../GlobalState';
import router from './router';
import siteMeta from '../siteMeta';
import useMetaService from '../service/metaService';

const localStorage = window.localStorage;
const RouterListner = ({children, history, location, match, staticContext}) => {
    const setRouter = useGlobalState('router')[1];
    const setTitle = useGlobalState('title')[1];
    const setMatchedRouter = useGlobalState('matchedRouter')[1];
    const [user, setUser] = useGlobalState('user');
    const {fetchMeta} = useMetaService();

    const getContext = useCallback(() => {
        const json = localStorage.getItem("user");
        const user = JSON.parse(json);
        setUser(json && user);
        
        // 获取系统
        fetchMeta();
    }, []);

    const siteName = siteMeta.basic.title;

    const onLocationChanged = useCallback(routerInfo => {
        setRouter(r => ({...r, ...routerInfo}));

        if (routerInfo.location) {
            const pathname = routerInfo.location.pathname;

            const currentRouter = router.filter(r => r.path === pathname)[0];
            let titleName = null;
            if (currentRouter) {
                titleName = currentRouter.title;
                setMatchedRouter(currentRouter);
                if (!currentRouter.withoutAuth && !user) {
                    getContext();
                }
            }
            else {
                setMatchedRouter(null);
                // routerInfo.history.push('/404');
                routerInfo.history.push('/index');
            }
            document.title = titleName ? (titleName + '-' + siteName) : siteName;
            setTitle(titleName);
        }
    }, [user]);

    useLayoutEffect(() => {
        onLocationChanged({history, location, match, staticContext});
        return history.listen(location => onLocationChanged({location}));
    }, [history, location, match, staticContext, onLocationChanged]);


    return <>{children}</>;
}

export default withRouter(RouterListner);