import React from 'react';
import {Input, FormItem, Select} from 'formsy-antd';
import {formItemLayout} from './formItemLayout';

const {Option} = Select;


const createInputItem = function (defaultLabel, defaultInputProps) {
    return function ({colon, extra, hasFeedback, help, label = defaultLabel, labelCol = formItemLayout.labelCol, required, validateStatus, wrapperCol = formItemLayout.wrapperCol, ...rest}) {
        return (
            <FormItem {...{colon, extra, hasFeedback, help, label, labelCol, required, validateStatus, wrapperCol}}>
                <Input required={required} {...defaultInputProps} {...rest} />
            </FormItem>
        )
    }
}

const createSelect = function (defaultLabel, defaultInputProps) {
    return function ({label=defaultLabel, required,name}) {
        return (<FormItem {...{required, label,name}} >
            <Select>
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
            </Select>
        </FormItem>)
    }
}

const PhoneInput = createInputItem('手机号', {
    validations: {matchRegexp: /^1\d{10}$/},
    maxLength: 11,
    validationError: "手机号不正确",
    name: "phone",
    placeholder: "手机号"
});

const PasswordInput = createInputItem('密码', {
    type: 'password',
    maxLength: 16,
    validationError: "手机号不正确",
    name: "password",
    placeholder: "密码"
});

const NameInput = createInputItem('名称', {
    type: 'text',
    maxLength: 20,
    validationError: "名称不正确",
    name: "name",
    placeholder: "名称"
});

const ValidationCodeInput = createInputItem('短信验证码', {
    type: 'text',
    maxLength: 6,
    validationError: "短信验证码不正确",
    name: "validationCode",
    placeholder: "短信验证码"
});

export {createInputItem, createSelect, PhoneInput, PasswordInput, NameInput, ValidationCodeInput}