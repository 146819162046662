const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWith = labelCol => {
  return {
    labelCol: {
      xs: { span: 24 },
      sm: { span: labelCol },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 - labelCol },
    },
  };
}

const inlineItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
};

const noLabelItemLayout = {
  label:null,
  labelCol: null,
  wrapperCol: null,
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export {formItemLayout, tailFormItemLayout, noLabelItemLayout, inlineItemLayout, formItemLayoutWith}