import React, {useState} from 'react';
import {Layout, Icon, PageHeader} from 'antd';
import NavMenu from '../component/basic/NavMenu';
import siteMeta from '../siteMeta';
import FooterComponent from '../component/pure/Footer';
import HeadStatus from '../component/biz/HeadStatus';
import useGlobalState from '../GlobalState';


const {Header, Content, Footer} = Layout;

const MenuLayout = function ({children}) {
    const [collapsed, setCollapsed] = useState(false);
    const [popedoms, setPopedoms] = useGlobalState('popedoms');
    const [userPopedoms, setUserPopedoms] = useGlobalState('userPopedoms');

    /*
    shouldComponentUpdate(nextProps, nextState) {
      const pathChanged = this.props.location.pathname !== nextProps.location.pathname;
      const collapsedChanged = this.state.collapsed !== nextState.collapsed;
      return pathChanged || collapsedChanged;
    }
    */

    const [title] = useGlobalState('title');
    /**
     * 导航权限拦截
     * @type {Array.<*>}
     */
    let menu = [];
    if (popedoms != null) {
        menu = siteMeta.menu.filter(meta => {
            const {path,subMenu} = meta;
            // 判断该导航是否需要权限控制
            const value = popedoms.get(path);
            if (value && value == 'NAV') {
                if (userPopedoms.get(path)) {
                    if (subMenu && subMenu.length > 0) {
                        const subMenus = [];
                        subMenu.map(submenu => {
                            const subValue = popedoms.get(path + submenu.path);
                            if (subValue && subValue == 'NAV') {
                                if (userPopedoms.get(path)) {
                                    subMenus.push(submenu);
                                }
                            }

                        })
                        meta.subMenu = subMenus;
                    }
                    return true;
                }
            } else {
                if (subMenu && subMenu.length > 0) {
                    const subMenus = [];
                    subMenu.map(submenu => {
                        const subValue = popedoms.get(path + submenu.path);
                        if (subValue && subValue == 'NAV') {
                            if (userPopedoms.get(path)) {
                                subMenus.push(submenu);
                            }
                        }

                    })
                    meta.subMenu = subMenus;
                }
                return true;
            }
            return false;
        });
    }

    return (
        <Layout style={{minHeight: '100vh'}}>
            <NavMenu collapsed={collapsed} siteMeta={{...siteMeta, menu}}/>
            <Layout>
                <Header style={{background: '#fff', padding: 0}}>
                    <Icon
                        style={{cursor: "pointer", width: 50, height: "100%"}}
                        type={collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => setCollapsed(!collapsed)}
                    />
                    <HeadStatus style={{float: 'right', marginRight: 20}}/>
                </Header>
                <Content>
                    <PageHeader style={{margin: '30px 0  1px 16px'}} title={title}/>
                    <div style={{margin: '0 0 10px 16px', backgroundColor: 'white', padding: 16}}>
                        {children}
                    </div>
                </Content>
                <Footer>
                    <FooterComponent/>
                </Footer>
            </Layout>
        </Layout>
    )
}

export default MenuLayout;