import Axios, { AxiosRequestConfig } from "axios";
import {useState, useMemo} from "react";
import {message} from "antd";
import history from '../router/history';
import useGlobalState from "../GlobalState";
import qs from 'query-string';

let token = '';

function useHttpService(errorMessageDuration: number = 3) {
    const [loading, setLoading] = useState(false);
    let [user] = useGlobalState('user');
    if (!user){
        const json = localStorage.getItem("user");
        if (json) {
            user = JSON.parse(json);
        }
    }

    const switchLoadingState = (requestId: number, state: boolean) => {
        setLoading(state);
    }

    const httpService = (httpMethod: 'get' | 'post'| 'download') => (url: string, data: any) => {
        return new Promise<any>((resolve, reject) => {
            const requestId = Math.floor(Math.random() * 1000000);
            if (user && user.token) {
                token = user.token;
            }
            switchLoadingState(requestId, true);
            const method = httpMethod === 'download' ? 'get' : httpMethod;
            const config: AxiosRequestConfig = {
                method,
                headers: {'X-Token': token || ''},
                url: '/api' + url,
                params:( httpMethod === 'get' || httpMethod === 'download') ? data : null,
                data: data,
                paramsSerializer: params => {
                    return qs.stringify(params)
                  },
                timeout:10 *60 * 1000
            };
            if (httpMethod === 'download') {
                config.responseType = 'blob';
            }
            Axios(config).then(resp => {
                switchLoadingState(requestId, false);
                 // 需要下载
                 const contentDisposition = resp.headers['content-disposition'];
                 if (contentDisposition && contentDisposition.startsWith('attachment;')) {
                     let blob = new Blob([resp.data], { type: resp.headers['content-type'] })
                     let link = document.createElement('a')
                     link.href = window.URL.createObjectURL(blob)
                     let fileName = (contentDisposition.match(/filename="(.+)"/) || ['', 'file'])[1];
                     fileName = decodeURI(fileName);
                     link.download = fileName;
                     link.click();
                     resolve(fileName);
                 } else {
                     resolve(resp.data);
                 }
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response) {
                    const status = error.response.status;
                    if (status === 401) {
                        console.log('未登录,重定向...')
                        history.push('/login')
                    }
                    else if (error.response.data) {
                        errorMessageDuration && message.error(error.response.data.tips, errorMessageDuration);
                    }
                } else if (error.message === 'Network Error') {
                    errorMessageDuration && message.error("网络错误,请检查您的网络.", errorMessageDuration);
                } else {
                    errorMessageDuration && message.error(error.message, errorMessageDuration);
                }
                switchLoadingState(requestId, false);
                reject(error);
            });
        });

    }

    const http = {get: httpService('get'), post: httpService('post'), download: httpService('download')}

    const setToken = (value:string) => {
        token = value;
    }
    return {http, loading, setToken};
}


export default useHttpService;


