import {Menu, Avatar, Icon, Divider, Modal, Dropdown} from "antd";
import React, { Fragment } from 'react';
import useGlobalState from "../../GlobalState";
import useHttpService from "../../service/httpService";
import history from '../../router/history';

const HeadStatus = function ({children, ...rest}) {
    let [user, setUser] = useGlobalState('user');
    const {http} = useHttpService();
    user = user || {};

    const logout = () => {
        Modal.confirm({
            title: '确定退出系统?',
            content: '',
            onOk() {
                http.post('/v1/account/logout');
                setUser(null);
                history.push('/login');
                // 清除登录信息
                localStorage.removeItem('user');
            },
            onCancel() {
            },
        })
    }

    const menu = (
        <Menu>
          <Menu.Item>
            <a onClick={logout}><Icon type="logout" />&nbsp;&nbsp;退出</a>
          </Menu.Item>
        </Menu>
      );
    
      return (
        <Fragment>
          <span className="gray">{user.companyName}</span>
          <Dropdown overlay={menu}>
            <a {...rest}>
              <Avatar shape="square" size="large" icon="user" src={user.avatar}/>&nbsp;&nbsp;
              <span>{user.name}</span>
            </a>
          </Dropdown>
        </Fragment>
        
      );
}

export default HeadStatus;