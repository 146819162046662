import { useState } from "react";


function useFormValidation() {
  const [valid, setValid] = useState(false);

  const onValid = () => {
    setValid(true);
  }

  const onInvalid = () => {
    setValid(false);
  }

  const listeners = {onValid, onInvalid};
  return {listeners, valid}
}

export default useFormValidation;