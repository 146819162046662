import {createGlobalState} from 'react-hooks-global-state';

interface GlobalState {
    title: string,
    user: any,
    router: any,
    matchedRouter: any,
    properties: object,
    meta: object,
    metaNode: object;
    popedoms: any,
    userPopedoms: object,
    globalLoadings: Set<number>,
    globalLoading: boolean
}

const initialState: GlobalState = {
    title: '加载中....',
    user: null,
    router: null,
    matchedRouter: null,
    properties: {},
    meta: {},
    metaNode: new Map(),
    popedoms: null,
    userPopedoms: new Map(),
    globalLoadings: new Set(),
    globalLoading: false
};

const {GlobalStateProvider, useGlobalState} = createGlobalState(initialState);

export {GlobalStateProvider};
export default useGlobalState;
