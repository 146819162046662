import useGlobalState from "../GlobalState";
import moment from "moment";

const useRedirect = () => {
  const [router] = useGlobalState('router');
  return link => router.history.push(link)
};

const dateTimeFormat = (dateTimeString, format="YYYY/M/D HH:mm") => {
  if (!dateTimeString) {
    return dateTimeString;
  }
  return moment(dateTimeString).format(format);
}

const convertMomentFieldToString = (object, format="YYYY-MM-DD HH:mm:ss") => {
  if (!object) {
    return object;
  }
  const convertted = {...object};
  Object.keys(object).filter(k => moment.isMoment(object[k])).forEach(k => convertted[k] = object[k].format(format));
  return convertted;
}

function debounce(fn, wait) {
  let callback = fn;    
  let timerId = null;

  function debounced() {
      // 保存作用域
      let context = this;
      // 保存参数，例如 event 对象
      let args = arguments;        

      clearTimeout(timerId);        
      timerId = setTimeout(function() {            
          callback.apply(context, args);
      }, wait);
  }
  
  // 返回一个闭包
  return debounced;         
}

const $ = maybeNull => {
  if (maybeNull) {
    return maybeNull;
  }
  return {};
}

const chnNumChar = { 零:0, 一:1, 二:2, 三:3, 四:4, 五:5, 六:6, 七:7, 八:8, 九:9 };
const chnNameValue = {十:{value:10, secUnit:false}, 百:{value:100, secUnit:false}, 千:{value:1000, secUnit:false}}
const chineseToNumber = (chnStr) => {
  let rtn = 0;
  let section = 0;
  let number = 0;
  let secUnit = false;
  const str = chnStr.split('');

  for(let i = 0; i < str.length; i++){
    const num = chnNumChar[str[i]];
      if(typeof num !== 'undefined'){
          number = num;
          if(i === str.length - 1){
              section += number;
          }
      } else{
        const unit = chnNameValue[str[i]].value;
          secUnit = chnNameValue[str[i]].secUnit;
          if(secUnit){
              section = (section + number) * unit;
              rtn += section;
              section = 0;
          }else{
              section += (number * unit);
          }
          number = 0;
      }
  }
  return rtn + section;
}


const facade = function(){
  const host = window.location.host;
  if (host.startsWith("museum.bytejz.com")) {
    return {
      materialName : "藏品",
      siteName : '藏品管理系统',
      loginBG : 'https://static.bytejz.com/img/pexels-ricardo-esquivel-2100935.jpg?x-oss-process=image/resize,w_1080',
      image: true
    }
  }
  return {
    materialName : "物料",
    siteName : '库房管理系统',
    loginBG: 'https://static.bytejz.com/img/6234514672364.jpg?x-oss-process=image/resize,w_1080',
    image: false
  }
};

const invalidToNull = value => {
  let v = value ? parseInt(value) : null;
  return v || null;
}

const materialQueryConvert = q => {
  let {page, pageSize, name, mount, status, parentId, storageLevelId, materialId, ...otherParam} = q;
  parentId = invalidToNull(parentId);
  storageLevelId = invalidToNull(storageLevelId);
  const rangeKeys = {};
  const conditions = Object.keys(convertMomentFieldToString(otherParam)).filter(k => {
      const value = otherParam[k];
      if (!value) {
          return false;
      }
      const starts = /(.+)_0/g.exec(k);
      const ends = /(.+)_1/g.exec(k);
      if (starts) {
          const rangKey = starts[1];
          rangeKeys[rangKey] = rangeKeys[rangKey] ? value+rangeKeys[rangKey] : value+',';
      }
      if (ends) {
          const rangKey = ends[1];
          rangeKeys[rangKey] = rangeKeys[rangKey] ? rangeKeys[rangKey] + value : ','+value;
      }
      return !starts && !ends && typeof(value) != "undefined";
  }).map(k => ({key: k, value: otherParam[k]}));
  Object.keys(rangeKeys).forEach(k => conditions.push({key: k, value: rangeKeys[k]}));
  let mountValue = null;
  if (mount === '1') {
      mountValue = false;
  }
  else if (mount === '2') {
      mountValue = true;
  }
  return {page, pageSize, name, parentId, storageLevelId,status, mount: mountValue, materialAttributes: conditions};
}

export {useRedirect, dateTimeFormat, $, convertMomentFieldToString, debounce, chineseToNumber, facade, materialQueryConvert}