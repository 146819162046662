import { facade } from "./helper/functions";

const siteMeta = {
  basic: {
    title: facade().siteName,
    logo: '',
    theme: 'dark', // 'light','dark'
  },
  menu: [
      {
          label: facade().materialName,
          path: "/materials",
          icon: "codepen-circle"
      },
      {
          label: "借出与归还",
          path: "/material_operate",
          icon: "credit-card"
      },
      {
          label: "申请单",
          path: "/apply_and_approve",
          icon: "audit"
      },
      {
          label: "保密管理",
          path: "/security_levels",
          icon: 'lock'
      },
      {
          label: "账号管理",
          path: "/account",
          icon: "user",
          subMenu: [
            {
              label: "用户",
              path: "/users",
            },
            {
              label: "角色",
              path: "/roles",
            }
          ]
      },
      {
          label: "设备管理",
          path: "/devices",
          icon: "printer"
      }
      ,
      {
          label: "费用",
          path: "/cost",
          icon: "pay-circle"
      },
     {
          label: "数据导入",
          path: "/import_data",
          icon: "import"
      },
      /*{
         label: "设置",
         path: "/403",
         icon: "tool"
     }*/
  ]
}

export default siteMeta;