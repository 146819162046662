import useHttpService from './httpService';
import {useCallback} from 'react';
import useGlobalState from '../GlobalState';

const localStorage = window.localStorage;

function useMetaService() {
    const {http} = useHttpService();
    const [metaNode, setMetaNode] = useGlobalState('metaNode');
    const [popedoms, setPopedoms] = useGlobalState('popedoms');
    const [userPopedoms, setUserPopedoms] = useGlobalState('userPopedoms');

    const fetchMeta = useCallback(() => {
        let meta = null;
        if (localStorage) {
            const metajson = localStorage.getItem('meta');
            if (metajson) {
                meta = JSON.parse(metajson);
                setMetaNode(meta);
            }
        }
        http.get('/v1/instance/meta').then(res => {
            // 将系统参数保存进statue
            if (localStorage) {
                localStorage.setItem("meta", JSON.stringify(res));
            }
            setMetaNode(res);
            const popedomMap = new Map();
            res.popedoms.map(popedom => popedomMap.set(popedom.value, popedom.type));
            setPopedoms(popedomMap);
            const userPopedomMap = new Map();
            res.userPopedoms.map(popedom => userPopedomMap.set(popedom.value, popedom.type));
            setUserPopedoms(userPopedomMap);
        });
    }, []);

    return {fetchMeta}
}

export default useMetaService;