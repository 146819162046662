import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react';
import page from '../decorator/page';
import BottomFooterLayout from '../layout/BottomFooterLayout';
import {Icon, Button, Tabs, Modal, List, message, Row, Col} from 'antd';
import {Form, FormItem, Input} from 'formsy-antd';
import {noLabelItemLayout} from '../component/form/formItemLayout';
import {ValidationCodeInput} from '../component/form/FormItems';
import hash from 'hash.js';
import useHttpService from '../service/httpService';
import useFormValidation from '../service/formService';
import useGlobalState from '../GlobalState';
import QRCode from 'qrcode.react';
import styles from '../styles/login.module.css'
import useMetaService from '../service/metaService';
import { facade } from '../helper/functions';

const timeStep = 5000;
const Login = function () {
    const {listeners, valid} = useFormValidation();
    const {http, loading} = useHttpService();
    const setUser = useGlobalState('user')[1];
    const [tabIndex, setTabIndex] = useState("1");
    const [loginToken, setLoginToken] = useState();
    const [loginQR,setLoginQR] = useState();
    const [reloadLoginToken, setReloadLoginToken] = useState(true);
    const {fetchMeta} = useMetaService();
    const [accounts, setAccounts] = useState();
    const [phone, setPhone] = useState();
    const [phoneValid, setPhoneValid] = useState(false);
    const [intervalId, setIntervalId] = useState();
    const [vctime, setVctime] = useState(0);
    const intervalRef = useRef();
    const checkToken = useRef();

    const doLogin = useCallback(key => {
        http.post('/v1/account/login_to_instance', {token: key}).then(u => {
            if (u) {
                setUser(u);
                // 将用户登录信息持久化到本地
                console.log(JSON.stringify(u));
                localStorage.setItem("user", JSON.stringify(u));
                fetchMeta();
                window.location = '/index'
            }
        }); 
    }, [])

    const processLoginInfo = useCallback((loginInfo) => {
        if (loginInfo) {
            if (loginInfo.instanceLogins.length < 1) {
                setReloadLoginToken(true);
                message.error(`${loginInfo.nickname||"抱歉"},您还没有注册本系统!`, 5);
            } else if (loginInfo.instanceLogins.length === 1) {
                doLogin(loginInfo.instanceLogins[0].key);
            } else {
                setAccounts(loginInfo);
            }
        }
    }, [doLogin])

    const startTimer = (expired) => {
        setVctime(expired);
        setIntervalId(setInterval(() => {
          if (vctime < 0 ){
            clearInterval(intervalId);
          } else {
            setVctime(v => v-1);
          }
        }, 1000))
    }

    const phoneChange = useCallback((value) => {
        const isPhone = /^1[0-9]{10}$/.test(value);
        setPhoneValid(isPhone);
        setPhone(isPhone ? value : null);
    }, []);

    const sendVC = () => {
        if (vctime <= 0 && phoneValid && !loading) {
            http.post("/v1/account/send_validation_code", {phone}).then(resp => startTimer(resp.value || 5*60));
        }
    }

    useMemo(() => {
        checkToken.current = () => {
            http.get('/v1/account/auth_token?token=' + loginToken + '&t=' + new Date().getTime()).then(processLoginInfo);
        }
    }, [loginToken, processLoginInfo]);

    useEffect(() => {
        if (tabIndex === "1" && reloadLoginToken) {
            setReloadLoginToken(false);
            http.post('/v1/account/generate_token').then(resp => {
                let expiredIn = resp.expiredTime;
                setLoginToken(resp.token);
                setLoginQR(`${resp.authUrl}?token=${resp.token}`);
                intervalRef.current = setInterval(() => {
                    if (!accounts){
                        checkToken.current();
                    }
                    expiredIn -= (timeStep / 1000);
                    if (expiredIn <= 0) {
                        setLoginToken(null);
                        clearInterval(intervalRef.current);
                    }
                }, timeStep);

            });

        }
        if (tabIndex !== "1") {
            setReloadLoginToken(true);
        }
        return () => clearInterval(intervalRef.current);
    }, [tabIndex, reloadLoginToken, accounts])

    const loginFormProps = {
        onValidSubmit: query => {
            http.post('/v1/account/auth_by_validation_code', query).then(processLoginInfo);
        }
    }

    return (
        <div className="text-center">
            <h1 className={styles.headText}><span>{facade().siteName}</span></h1>
            <h3 className={styles.subHeadText}>用户登录</h3>
            <div className={styles.loginWindow}>
                <Tabs defaultActiveKey="1" onChange={setTabIndex}>
                    <Tabs.TabPane tab="扫码登录" key="1">
                        {!!loginQR &&
                        <div>
                            <QRCode size={280} value={loginQR}/>
                            <div className='gray'>请使用微信扫码登录</div>
                        </div>}
                        {!loginQR && !loading &&
                        <div onClick={() => setReloadLoginToken(true)} className={styles.expiredMask}>
                            <Icon type="reload"/>
                            <div className="font14">二维码已过期,请点击刷新</div>
                        </div>
                        }
                        {
                            loading && !loginQR && <Icon className="font30" type="loading"/>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="手机验证码登录" key="2" className="text-left">
                        <br/>
                        <Form {...loginFormProps} {...listeners}>
                            <FormItem>
                                <Row gutter={16}>
                                    <Col span={16}>
                                        <Input prefix={<Icon type="mobile"/>} name="phone" required placeholder="请输入手机号" onChange={phoneChange}/>
                                    </Col>
                                    <Col span={8} style={{textAlign: 'right'}}>
                                    <Button disabled={vctime > 0 || loading || !phoneValid} onClick={sendVC}>{vctime > 0 ? `${vctime}s` : '发送验证码'}</Button>
                                    </Col>
                                </Row>
                            </FormItem>
                            <ValidationCodeInput {...noLabelItemLayout} prefix={<Icon type="lock"/>} required/>
                            <FormItem>
                                <Button type="primary" htmlType="submit" disabled={!valid || loading}
                                        className="width-full">{loading ? '登录中....' : '登录'}</Button>
                            </FormItem>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <Modal title="请选择要登录的库房实例:" visible={accounts && accounts.instanceLogins.length > 1 && !!loginQR} onCancel={()=>setAccounts(null)} footer={null}>
                <List itemLayout="horizontal" dataSource={accounts && accounts.instanceLogins} renderItem={item => (
                    <List.Item key={item.key} actions={[<Button type="primary" onClick={()=>doLogin(item.key)}>进入</Button>]} >
                        <List.Item.Meta title={item.name} description={item.description} />
                    </List.Item>
                    )}
                />
            </Modal>
        </div>
    )
}

export default page({
    layout: BottomFooterLayout,
    backgroundImage: facade().loginBG,
    backgroundColor: 'rgba(0,0,0,0.6)'
})(Login);