import { facade } from "../helper/functions";
import Login from "../page/Login";


export default [
    {path: "/login", title: "登录", component: Login, withoutAuth: true},

    {path: "/index", title: "概览与摘要", component: './page/Index'},
    {path: "/materials", title: facade().materialName + "管理", component: './page/Material'},
    {path: "/material_card", title: facade().materialName + "卡", component: './page/MaterialCard'},
    {path: "/account/users", title: "账号管理", component: './page/Users'},
    {path: "/account/roles", title:"角色管理", component: './page/Roles'},
    {path: "/devices", title:"设备管理", component: './page/Devices'},
    {path: "/403", title:"", component: './page/403'},

    {path: "/apply_and_approve", title:"申请单", component: './page/ApplyAndApprove'},
    {path: "/material_apply", title:"申请单详情", component: './page/MaterialApply'},
    {path: "/material_history", title:"申请历史记录", component: './page/MaterialHistory'},

    {path: "/cost", title:"费用", component: './page/Cost'},
    {path: "/statement", title:"", component: './page/403'},

    {path: "/security_levels", title:"保密级别", component: './page/SecurityLevels'},
    {path: "/material_operate", title:"借出归还", component: './page/MaterialOperate'},

    {path: "/import_data", title:"数据导入", component: './page/ImportData'}
]